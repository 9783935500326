<template>
  <div class="home">
    <section class="category-section">
      <div class="category-list">
        <i class="iconfont icon-category"></i>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'Category', params: { categoryId: category.id } }" class="category-item">
              {{ category.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <router-link to="/category" class="view-all">
        <div class="allRight">
          <div>全部分类</div>
          <img src="../assets/right.png" alt="">
        </div>
      </router-link>
    </section>
    <div class="homeBtn">
      <main>
        <!-- <div class="banner">
        </div> -->

        <section class="manga-section vipSection">
          <div class="manga-section-header">
            <div class="mangaHeader">
              <div class="headerBox">资费作品</div>
              <div class="cartoon-sub-title">资费作品</div>
            </div>
            <router-link :to="{ name: 'Category', params: { isVipId: 1 } }" class="more-link">更多></router-link>
          </div>
          <div class="vipBox">

            <div class="boxBig">
              <div class="boxleft">
                <div class="signvipListLeft">

                  <div :class="`signItemLeft${index}`" v-for="(manga, index) in vipListLeft" :key="manga.id">
                    <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                      <img :src="manga.cover" :alt="manga.title">
                    </router-link>
                  </div>
                </div>
                <div>
                  <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px;">{{ vipListLeft[1]?.name }}</div>
                  <div v-if="vipListLeft[1]?.authorName">作者：{{ vipListLeft[1]?.authorName }}</div>
                  <p>{{ truncateDescription(vipListLeft[1]?.descr) }}</p>
                  <span class="rating" v-if="vipListLeft[1]?.dyNews">更新至：{{ vipListLeft[1]?.dyNews }}</span>
                </div>
              </div>
              <div class="gridBigBox">
                <div class="gidBox">
                  <div class="vipGrid">
                    <div class="item" v-for="manga in vipList" :key="manga.id">
                      <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                        <img :src="manga.cover" :alt="manga.title">
                        <div style="font-size: 16px;font-weight: bold;margin-bottom: 5px;margin-top: 10px;">{{
                          manga?.name }}</div>
                        <p>{{ truncateDescription(manga.descr) }}</p>
                        <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="signAndprivate">
          <section class="mangaSectionSign">
            <div class="manga-section-header">
              <div class="mangaHeader">
                <div class="headerBox">独家作品</div>
                <div class="cartoon-sub-title">独家作品</div>
              </div>
              <router-link :to="{ name: 'Category', params: { privateId: 1 } }" class="more-link">更多></router-link>
            </div>
            <div class="mangaListContainer">
              <button class="scroll-button left" @click="scrollLeft">◀</button>
              <div class="mangaList sign-item-container" ref="mangaList">
                <div class="mangaItem" v-for="manga in privateList" :key="manga.id">
                  <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                    <div>{{ manga.name }}</div>
                    <img class="mangaItemImg" :src="manga.cover" :alt="manga.title">
                    <p v-if="manga.descr">{{ truncateDescription(manga.descr) }}</p>
                    <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                  </router-link>
                </div>
              </div>
              <button class="scroll-button right" @click="scrollRight">▶</button>
            </div>
          </section>
          <section class="signSection">
            <div class="manga-section-header">
              <div class="mangaHeader">
                <div class="headerBox">签约作品</div>
                <div class="cartoon-sub-title">签约作品</div>
              </div>
              <router-link :to="{ name: 'Category', params: { signId: 1 } }" class="more-link">更多></router-link>
            </div>
            <div class="sign-grid">
              <button class="scroll-button left" @click="scrollLeftSign">◀</button>
              <div class="sign-item-containerSign">
                <div class="sign-item" v-for="manga in signList" :key="manga.id">
                  <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                    <div>{{ manga.name }}</div>
                    <img :src="manga.cover" :alt="manga.title">
                    <div>{{ truncateDescription(manga.descr) }}</div>
                    <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                  </router-link>
                </div>
              </div>
              <button class="scroll-button right" @click="scrollRightSign">▶</button>
            </div>
          </section>
        </div>

        <section class="manga-section">
          <div class="manga-section-header">
            <div class="mangaHeader">
              <div class="headerBox">最近更新</div>
              <div class="cartoon-sub-title">最近更新</div>
            </div>
            <router-link :to="{ name: 'Category', params: { premiumId: 1 } }" class="more-link">更多></router-link>
          </div>
          <div class="mangeBox">
            <div class="manga-grid">
              <div class="manga-item" v-for="manga in premiumList" :key="manga.id">
                <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
                  <img :src="manga.cover" :alt="manga.title">
                  <h4>{{ manga.name }}</h4>
                  <p>{{ truncateDescription(manga.descr) }}</p>
                  <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import apiService from '@/api/api';
export default {
  name: 'Home',
  data() {
    return {
      premiumList: [],
      privateList: [],
      signList: [],
      vipList: [],
      vipListLeft: [],
      categories: [],
      scrollAmount: 250, // 每次滚动的像素量
      privateRandomManga: null,
      premiumListRandomManga: null,
      showArrows: false,
    }
  },
  methods: {
    fetchCategories() {
      apiService.getCategory().then(response => {
        if (response.data.code === 0) {
          this.categories = response.data.data[0].category;
        }
      });

    },
    fetchHomeData() {
      apiService.getHomeData().then(response => {
        if (response.data.code === 0) {
          let r = response.data.data;
          this.premiumList = r.premium; //热门
          this.privateList = r.private; //独家
          this.signList = r.sign.slice(0, 6);  //签约
          this.vipList = r.vip.slice(2, 10); //资费
          this.vipListLeft = r.vip.slice(0, 3); //资费
        }
      });
    },
    truncateDescription(descr) {
      //增加一个判断，如果descr是null，则返回空字符串
      if (descr == null) {
        return '';
      }
      if (descr.length > 10) {
        return descr.substring(0, 10) + '...';
      }
      return descr;
    },

    getRandomManga() {
      if (this.privateList.length === 0) {
        this.privateRandomManga = null;
        return;
      }
      const randomIndex = Math.floor(Math.random() * this.privateList.length);
      this.privateRandomManga = this.privateList[randomIndex];
    },
    getpremiumListRandomManga() {
      if (this.premiumList.length === 0) {
        this.premiumListRandomManga = null;
        return;
      }
      const randomIndex = Math.floor(Math.random() * this.premiumList.length);
      this.premiumListRandomManga = this.premiumList[randomIndex];
    },
    scrollLeft() {
      const container = this.$el.querySelector('.sign-item-container');
      container.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
    },
    scrollRight() {
      const container = this.$el.querySelector('.sign-item-container');
      container.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
    },
    scrollLeftSign() {
      const container = this.$el.querySelector('.sign-item-containerSign');
      container.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
    },
    scrollRightSign() {
      const container = this.$el.querySelector('.sign-item-containerSign');
      container.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
    },
  },

  mounted() {
    this.fetchHomeData();
    this.getRandomManga();
    this.fetchCategories();
    this.getpremiumListRandomManga()

    // 设置定时器每3秒更新一次随机漫画
    this.interval = setInterval(() => {
      this.getRandomManga();
      this.getpremiumListRandomManga();
    }, 3000);
  }
}
</script>

<style scoped>
.home {
  font-family: Arial, sans-serif;
  /* max-width: 1500px; */

  /* margin: 0 auto; */
  /* padding: 0 20px; */
}

.homeBtn {
  margin-left: 40px;
  max-width: 1500px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo img {
  height: 40px;
}

.search-bar {
  display: flex;
}

.search-bar input {
  padding: 5px 10px;
  width: 300px;
}

.search-bar button {
  padding: 5px 15px;
  background-color: #7fc63a;
  color: white;
  border: none;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.login-btn {
  background-color: #7fc63a;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: #f0f0f0;
}

nav ul li {
  padding: 10px 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.active {
  color: #7fc63a;
}

.banner {
  height: 300px;
  background-color: #ddd;
  margin: 20px 0;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.manga-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mangaHeader {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.manga-section-header .headerBox {
  font-size: 24px;
  color: #333;
  margin-right: 10px;
  cursor: default;
}

.cartoon-sub-title {
  font-size: 14px;
  color: #999;
  cursor: default;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
}

.category-section {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0; */
  /* margin-top: 20px; */
  margin-left: 40px;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  /* border: 1px solid #f9f9f9; */
  color: #333;
  font-size: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 27px;
  font-size: 16px;
  /* box-shadow: 0 0 12px 0 rgba(221, 221, 221, .4); */
}

.category-list {
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
}

.category-list ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list ul li {
  margin-right: 10px;
  /* margin-bottom: 10px; */
  list-style: none;
}

.category-list ul li a {
  text-decoration: none;
  color: #333;
  list-style: none;
}

.manga-section {
  margin-bottom: 80px;
}

.manga-section h2 {
  border-left: 4px solid #7fc63a;
  padding-left: 10px;
}

.manga-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.manga-item {
  text-align: center;
  font-size: 12px;
}

.manga-item a {
  text-decoration: none;
  color: #fff;
}

.manga-item img {
  width: 200px;
  height: 200px;
  /* height: auto; */
}

.manga-item h4 {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0;
}

.manga-item span {
  font-size: 10px;
}

.rating {
  background-color: #7fc63a;
  padding: 2px 5px;
  border-radius: 3px;
}

.manga-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.manga-list .manga-item {
  display: flex;
  text-align: left;
}

.manga-list .manga-item img {
  width: 80px;
  height: 120px;
  margin-right: 10px;
}

.more-link {
  display: block;
  text-align: right;
  color: #666;
  font-size: 16px;
  text-decoration: none;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 10px;
  color: #666;
  text-decoration: none;
}

.vipBox img:hover {
  transform: scale(1.1);
  /* 鼠标悬停时放大 */
}

.allRight {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7fc63a;
}

.allRight img {
  width: 20px;
  height: 20px;
}

.mangeBox {
  display: flex;
}


.mangaBig {
  width: 400px;
  vertical-align: top;
  height: 400px;
  text-align: center;
}

.sign-grid {
  display: flex;
  align-items: center;
  /* 使内容和箭头垂直居中 */
  overflow: hidden;
  height: 503px;
}

.sign-grid .sign-item-container {
  display: flex;
  width: 100%;
  height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.sign-item-containerSign {
  display: flex;
  width: 100%;
  height:240px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}



.sign-grid .sign-item-container .signListBox h4 {
  width: 500px;
  text-align: left;
}

.sign-grid .sign-item-container .signListBox img {
  width: 185px;
  height: 220px;
}

.sign-grid .sign-item-container::-webkit-scrollbar,
.sign-item-containerVip::-webkit-scrollbar {
  display: none;
}

.sign-grid .sign-item {
  width: 232px;
  height: 232px;
  text-align: center;
  margin: 0 8px;
}

.sign-item-container,
.sign-item-containerVip {
  height: 460px;
}

.sign-grid .sign-item img {
  width: 180px;
  height: 180px;
}

.arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;

}

.mangaSectionSign,
.signSection {
  width: 44%;
  flex: 1;
  overflow: hidden;
  margin-right: 40px;
}

.mangaSectionSign .mangaListContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.mangaSectionSign .mangaListContainer .mangaItem {
  height: 240px;
}

.mangaSectionSign .mangaList {
  display: flex;
  height: 240px;
  text-align: center;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.mangaSectionSign .mangaList::-webkit-scrollbar {
  display: none;
}


.mangaSectionSign .scroll-button {
  background: none;
  border: none;
  cursor: pointer;

  display: none;
  font-size: 24px;
}

.mangaSectionSign .left {
  width: 50px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  left: 0;
  box-shadow: 12px 12px 12px 12px rgba(102, 95, 92, .4);
}

.mangaSectionSign .right {
  width: 50px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  right: 0;
  box-shadow: 12px 12px 12px 12px rgba(102, 95, 92, .4);
}

.mangaSectionSign .mangaListContainer:hover .scroll-button {
  display: block;
}

.mangaList {
  display: flex;
}

.mangaList img {
  width: 200px;
  height: 200px;

}

.signvipListLeft {
  height: 268px;
  position: relative;
}

.signvipListLeft .signItemLeft0 {
  display: inline;
  width: 200.8px;
  height: 234.6px;
  left: -25.9px;
  top: 31.7px;
  visibility: visible;
  position: absolute;
  z-index: 2;
  opacity: 1;
  border-width: 0px;
}

.signvipListLeft .signItemLeft1 {
  display: inline;
  width: 203px;
  height: 253px;
  left: 132px;
  top: 6px;
  visibility: visible;
  position: absolute;
  z-index: 3;
  opacity: 1;
  border-width: 10px;
}

.signvipListLeft .signItemLeft2 {
  display: inline;
  width: 200.8px;
  height: 234.6px;
  left: 275.1px;
  top: 31.7px;
  visibility: visible;
  position: absolute;
  z-index: 2;
  opacity: 1;
  border-width: 0px;
}

.signvipListLeft .signItemLeft0 img,
.signItemLeft2 img {
  width: 200.8px;
  height: 234.6px;
}

.signvipListLeft .signItemLeft1 img {
  width: 203px;
  height: 253px;
}

.vipBox {
  width: 450px;
}

.boxBig {
  position: relative;
  width: 500px;
}

.vipBox .boxleft {
  width: 500px;
}

.vipGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.vipGrid .item {
  text-align: center;
}

.gridBigBox {
  position: absolute;
  right: 0;
  top: 0;
}

.gidBox {
  position: absolute;
  margin: 0 -2.5px;
  float: right;
  width: 688px;
  z-index: 2;
}

.vipGrid .item img {
  width: 160px;
  height: 160px;
}

.vipSection {
  margin-bottom: 180px;
}

.signSection {
  margin-bottom: 40px;
}

.signSection .sign-grid::-webkit-scrollbar {
  display: none;
  height: 240px;
}


.signSection .sign-grid .scroll-button {
  background: none;
  border: none;
  cursor: pointer;

  display: none;
  font-size: 24px;
}

.signSection .sign-grid {
  position: relative;
  height: 240px;
}

.signSection .sign-grid  .left {
  width: 30px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  left: 0;
  box-shadow: 12px 12px 12px 12px rgba(102, 95, 92, .4);
}

.signSection .sign-grid  .right {
  width: 30px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  right: 0;
  box-shadow: 12px 12px 12px 12px rgba(102, 95, 92, .4);
}

.signSection .sign-grid:hover .scroll-button {
  display: block;
}
.signAndprivate {
  display: flex;
}
</style>