<template>
  <div class="user-center-layout">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>编辑漫画</h4>
        <p>请修改以下信息来更新漫画作品。</p>
      </div>
      <div class="actions">
        <CaricatureForm 
          :isEdit="true" 
          :initialData="caricatureData" 
          @submit-success="handleSubmitSuccess" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue';
import CaricatureForm from '@/components/CaricatureForm.vue';
import apiService from '@/api/api';

export default {
  name: 'CaricatureEdit',
  components: {
    AuthorSidebar,
    CaricatureForm
  },
  data() {
    return {
      caricatureData: {}
    };
  },
  created() {
    this.fetchCaricatureData();
  },
  methods: {
    fetchCaricatureData() {
      const caricatureId = this.$route.params.id;
      apiService.getCaricatureById(caricatureId).then(res => {
        if (res.data.code === 0) {
          this.caricatureData = res.data.data;
        } else {
          this.$notify.error('获取漫画数据失败');
        }
      }).catch(error => {
        console.error('获取漫画数据时出错:', error);
        this.$notify.error('获取漫画数据时出错');
      });
    },
    handleSubmitSuccess() {
      // 处理提交成功后的操作，比如跳转页面
      this.$router.push('/author/caricature-list');
    }
  }
};
</script>

<style scoped>
/* 可以复用 CaricatureAdd.vue 的样式 */
</style>