<template>
  <div class="reset-password-page">
    <div class="reset-password-container">
      <h2>找回密码</h2>
      <form @submit.prevent="handleResetPassword">
        <span class="error-message" v-if="resetError">{{ resetError }}</span>
        <div class="input-group">
          <input type="tel" v-model="phone" placeholder="请输入手机号" required @blur="validatePhone">
          <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        </div>
        <div class="input-group verification-code">
          <input type="text" v-model="verificationCode" placeholder="请输入验证码" required>
          <img :src="captchaImageData" alt="验证码" @click="refreshCaptcha" class="captcha-image">
        </div>
        <div class="input-group">
          <input type="password" v-model="newPassword" placeholder="请输入新密码" required>
        </div>
        <button type="submit" class="reset-btn">重置密码</button>
      </form>
      <div class="login-link">
        记起密码了？<router-link to="/login">立即登录</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validatePhoneNumber } from '@/utils/validators';
import apiService from '@/api/api';

export default {
  name: 'ResetPassword',
  data() {
    return {
      phone: '',
      verificationCode: '',
      newPassword: '',
      captchaImageData: 'https://bjyouliang.com/app-api/api/auth/verification',
      phoneError: '',
      resetError: ''
    }
  },
  methods: {
    handleResetPassword() {
      // 调用API进行密码重置
      apiService.resetPassword(this.phone, this.verificationCode, this.newPassword).then(response => {
        if(response.data.code === 200){
          this.$router.push('/login');
        } else {
          this.resetError = response.data.msg;
        }
      }).catch(error => {
        console.error('重置密码失败:', error);
        this.resetError = '重置密码失败,请稍后再试';
      });
    },
    refreshCaptcha() {
        const timestamp = new Date().getTime();
      // 将时间戳添加到验证码图片URL中,以确保每次刷新都获取新的验证码
      this.captchaImageData = `https://bjyouliang.com/app-api/api/auth/verification?t=${timestamp}`;
    },
    validatePhone() {
      this.phoneError = validatePhoneNumber(this.phone);
    }
  }
}
</script>

<style scoped>
.captcha-image{
    height: 30px;
}
.reset-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f5f5f5;
}

.reset-password-container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

input[type="tel"],
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.verification-code {
  display: flex;
}

.verification-code input {
  flex-grow: 1;
  margin-right: 10px;
}

.captcha-image {
  cursor: pointer;
  height: 38px;
}

.reset-btn {
  width: 100%;
  padding: 10px;
  background-color: #7fc63a;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.reset-btn:hover {
  background-color: #ff5252;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: #7fc63a;
  text-decoration: none;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}
</style>