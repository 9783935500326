<template>
    <div class="about-page">
      <el-tabs v-model="activeTab" type="card" @tab-click="handleTabClick">
        <el-tab-pane label="关于我们" name="about" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系我们" name="contact" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="网站地图" name="sitemap" class="about-tab">
          <div class="tab-content">
          </div>
        </el-tab-pane> -->
        <el-tab-pane label="法律声明" name="legal" class="about-tab">
          <div class="tab-content">
            <!-- 法律声明内容 -->
             <div v-html="content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="作品要求" name="requirements" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
                    <!-- 漫画签约内容 -->
        <el-tab-pane label="漫画签约" name="contract" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="审核机制" name="review" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="福利体系" name="benefits" class="about-tab">
          <div class="tab-content">
            <div v-html="content"></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import { apiService } from '@/api/api';
  export default {
    name: 'About',
    data() {
      return {
        activeTab: 'about',
        about: null,
        content: null,
        paramsType: {
          "about": 39,
          "contact": 40,
          "sitemap": 41,
          "legal": 42,
          "requirements": 43,
          "contract": 44,
          "review": 45,
          "benefits": 46,
        }
      }
    },
    created() {
      this.setActiveTab();
      this.getAbout();
    },
    watch: {
      '$route.query.tab': {
        handler: 'setActiveTab',
        immediate: true
      }
    },
    methods: {
      getAbout() {
        let params = {
          id: this.paramsType[this.activeTab]
        }
        console.log(params)
        apiService.getAbout(params).then(response => {
          this.content = response.data.data.content;
        }).catch(error => {
          console.error('Error fetching about information:', error);
        });
      },
      setActiveTab() {
        const tab = this.$route.query.tab;
        if (tab && ['about', 'contact', 'sitemap', 'legal', 'requirements', 'contract', 'review', 'benefits'].includes(tab)) {
          this.activeTab = tab;
        } else {
          this.activeTab = 'about';
        }
      },
      handleTabClick(tab) {
        this.activeTab = tab.name;
        this.getAbout();
        this.$router.push({ name: 'About', query: { tab: tab.name } });
      }
    }
  }
  </script>
  
  <style scoped>
  .about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    align-items: center;
    border:none;
  }
  .tab-content
  {
    font-size: 14px;
    padding: 20px;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav
  {
    border: none;
  }
  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item
  {
    border-right: none;
    border-left: none;
  }

  .about-tab
  {
    border: none;
  } 
  /* 自定义 Element UI 标签样式 */
  ::v-deep .el-tabs__item {
    font-size: 14px;
    color: #666;
  }
  
  ::v-deep .el-tabs__item.is-active {
    color: #ff6600;
  }
  
  ::v-deep .el-tabs__active-bar {
    background-color: #ff6600;
  }
  
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #ff6600;
  }
  </style>