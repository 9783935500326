<template>
  <header class="banner">
    <div v-if="!isLoggedIn">
      <div class="banner-content">
        <!-- <div class="logo">
        <img src="@/assets/logo.png" alt="Logo">
      </div> -->
        <div class="logoName">
          优量漫画
        </div>
        <div class="logoCenter">
          <nav>
            <ul>
              <li><router-link to="/" exact>
                  <div class="imgTextBox">
                    <img :src="isHomeSelected ? require('@/assets/home1.png') : require('@/assets/home.png')"
                      alt="首页" />
                    <div>首页</div>
                  </div>
                </router-link></li>
              <!-- <li><router-link to="/update">更新</router-link></li> -->
              <li><router-link to="/rank">
                  <div class="imgTextBox">
                    <img :src="isRankSelected ? require('@/assets/Ranking1.png') : require('@/assets/Ranking.png')"
                      alt="排行" />
                    <div>排行</div>
                  </div>
                </router-link></li>
              <li><router-link to="/category">
                  <div class="imgTextBox">
                    <img
                      :src="isCategorySelected ? require('@/assets/classify1.png') : require('@/assets/classify.png')"
                      alt="分类" />
                    <div>分类</div>
                  </div>
                </router-link></li>
              <!-- <li><router-link to="/bookshelf">书架</router-link></li> -->
            </ul>
          </nav>
        </div>
        <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
        <div class="userInfo">
          <div class="user-info">
            <div>

              <!-- <span class="user-avatar"></span> -->
            </div>
            <div>
              <router-link v-if="isLoggedIn" to="/user" class="user-name">{{ userName }}</router-link>
              <router-link v-if="!isLoggedIn" to="/login" class="login-btn">
                <img src="../assets/mine.png" class="userMine" alt="" srcset="">
              </router-link>
            </div>
            <!-- <router-link :to="isLoggedIn ? '/user' : '/login'" class="user-name">
            {{ userName }}
          </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn">
      <div class="banner-content">
        <div class="logo">
          <div>优量漫画</div>
          <!-- <img src="@/assets/logo.png" alt="Logo"> -->
        </div>
        <div>
          <nav>
            <ul>
              <li><router-link to="/" exact>
                  <div class="imgTextBox">
                    <img :src="isHomeSelected ? require('@/assets/home1.png') : require('@/assets/home.png')"
                      alt="首页" />
                    <div>首页</div>
                  </div>
                </router-link></li>
              <li><router-link to="/rank">
                  <div class="imgTextBox">
                    <img :src="isRankSelected ? require('@/assets/Ranking1.png') : require('@/assets/Ranking.png')"
                      alt="排行" />
                    <div>排行</div>
                  </div>
                </router-link></li>
              <li><router-link to="/category">
                  <div class="imgTextBox">
                    <img
                      :src="isCategorySelected ? require('@/assets/classify1.png') : require('@/assets/classify.png')"
                      alt="分类" />
                    <div>分类</div>
                  </div>
                </router-link></li>
              <li><router-link to="/update-user-info">
                  <div class="imgTextBox">
                    <img
                      :src="isupdateUserInfo ? require('@/assets/set1.png') : require('@/assets/set.png')"
                      alt="分类" />
                    <div>账户设置</div>
                  </div>

                </router-link></li>
            </ul>
          </nav>
        </div>
        <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
        <div class="user-info go-author">
          <div class="user-info-item">
            <p @click="goUserCenter">用户中心</p>
          </div>
          <div class="user-info-item">
            <p @click="goAuthorCenter">作者中心</p>
          </div>
          <div class="user-info-item">
            <p @click="handleLogout">退出登录</p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import apiService from '@/api/api';
export default {
  name: 'Banner',
  computed: {
    ...mapState(['isLoggedIn', 'user']),
    isHomeSelected() {
      return this.$route.path === '/';
    },
    isRankSelected() {
      return this.$route.path === '/rank';
    },
    isCategorySelected() {
      return this.$route.path.includes('/category');
    },
    isupdateUserInfo() {
      return this.$route.path.includes('/update-user-info');
    }
  },
  data() {
    return {
      userName: '游客',
      isAuthor: false,
      userAvatar: '',
    }
  },
  methods: {
    ...mapActions(['logout', 'checkLoginStatus']),
    ...mapMutations(['setLoginStatus']),
    goAuthorCenter() {
      this.$router.push('/author-dashboard');
    },
    goUserCenter() {
      this.$router.push('/user');
    },
    handleLogout() {
      this.logout();
      if (this.$route.path !== '/') {
        this.$router.replace('/');
      }
    },
    async checkLoginStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await apiService.getUserInfo();
          if (response.data.code === 0) {
            this.setLoginStatus(true);
            this.userName = response.data.data.account || response.data.data.nickname;
            this.userAvatar = response.data.data.avatar;
          } else {
            this.setLoginStatus(false);
          }
        } catch (error) {
          console.error('获取用户信息失败:', error);
          this.setLoginStatus(false);
        }
      } else {
        this.setLoginStatus(false);
      }
    }
  },
  mounted() {
    this.checkLoginStatus();
  }
}
</script>

<style scoped>
.go-author {
  cursor: pointer;
  color: white;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;
}

.user-info-item {
  margin-right: 10px;
  height: 40px;
  /* display: flex;
  justify-items: center;
  align-items: center; */
  text-align: center;
  line-height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #7fc63a;
  color: white;
  padding: 0 10px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 16px;

}

.banner {
  width: 10%;
  font-family: Arial, sans-serif;
  background-color: #f0f1f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;

}

.logoCenter {
  flex: 1;
}

.logo img {
  height: 40px;
}

.logo div {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.search-bar {
  position: relative;
  display: inline-block;
}

input[type="text"] {
  padding: 8px 70px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* 修改这里来调整圆角程度 */
  width: 250px;
  font-size: 12px;
}

button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  /* 给按钮也添加一点圆角 */
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

button:hover {
  background: #e0e0e0;
}

.userInfo {
  /* position: absolute;
  bottom: 20%; */
  margin-top: 20px;
  width: 100px;
  text-align: center;
  /* display: flex;
  align-items: center; */
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

/* .login-btn,
.logout-btn {
  background-color: #7fc63a;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
} */


nav ul {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  /* display: flex; */
}

nav ul li {
  padding: 10px 20px;
  font-size: 17px;
}

nav ul li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

nav ul li .imgTextBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.router-link-active {
  color: #7fc63a;
}

.logoName {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  margin-right: 40px;
  padding: 5px;
  width: 200px;
}


.userMine {
  width: 50px;
  height: 50px;
}
</style>