<template>
  <div class="read-chapter">
    <div class="chapter-info">
      <object v-if="currentChapter.file" :data="currentChapter.file" type="application/pdf" width="100%" height="1080px"></object>
    </div>
    <div class="chapter-list">
      <ul class="floating-chapter-list">
        <li v-for="(chapter,index) in chapterList" :key="chapter.id" class="chapter-item" @click="selectChapterFn(index)">
          {{ chapter.name }}
        </li>
      </ul>
    </div>
    
    <!-- 新增的购买章节弹窗 -->
    <el-dialog
      title="当前章节为付费章节"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-content">
        <p>{{ selectChapter.name?selectChapter.name:'当前章节' }}</p>
        <div class="coin-info">
          <span>账户金币:  {{ userInfo ? userInfo.vo.gold : 0 }} 金币</span>
        </div>
        <div class="purchase-info">
          <!-- <span class="coin-icon">⭐</span> -->
          <span>{{ selectChapter.icon?selectChapter.icon:0 }} 金币</span>
          <el-button v-if="selectChapter.icon && selectChapter.icon > userInfo.coin" type="warning" size="small"  @click="initialTab = 'coins'; showBuyVip = true">
            金币不足，去充值
          </el-button>
          <el-button v-else type="primary" size="small"  @click="handlePurchase" class="purchase-btn">购买</el-button>
        </div>
        <el-checkbox v-model="autoNextChapter">
          自动购买下一章节
        </el-checkbox>
      </div>
    </el-dialog>
    <div v-if="showBuyVip">
      <el-dialog
        title="购买VIP"
        :visible.sync="showBuyVip"
        width="60%"
        center
        :before-close="handleCloseBuyService">
        <BuyService :initialTab="initialTab" />
      </el-dialog>
    </div>
    <LoginDialog :visible.sync="showLoginDialog" @login-success="onLoginSuccess" />
  </div>
</template>

<script>
import apiService from '@/api/api';
import BuyService from '@/components/BuyService.vue';
import LoginDialog from '@/components/LoginDialog.vue';
export default {
  name: 'ReadChapter',
  components: {
    BuyService,
    LoginDialog
  },
  data() {
    return {
      currentChapter:{
        file:null,
        id:null
      },
      chapterList:[],
      caricatureId:null,
      chapterId:null,
      userInfo:null,
      dialogVisible: false,
      autoNextChapter: false,
      selectChapter:{
        name:null,
        icon:null
      },
      showBuyVip:false,
      initialTab:'vip',
      showLoginDialog: false
    }
  },
  mounted() {
    this.caricatureId = this.$route.params.id;
    this.chapterId = this.$route.params.chapterId;
    this.fetchChapter(this.caricatureId,this.chapterId);
    this.getChapterList();
  },
  methods: {
    fetchChapter(id,chapterId) {
      // 从API获取章节数据
      apiService.getCaricatureChapter(id,chapterId).then(response => {
        if(response.data.code == 0){
          this.currentChapter = response.data.data;
          console.log("当前章节文件路径:",this.currentChapter.file);
        }else{
          this.$notify({
                title: '失败',
                message: '获取章节失败',
                type: 'error'
              });
        }
      }).catch(error => {
        console.error('获取章节失败:', error);
      });
    },
    getChapterList(){
    apiService.getCaricatureDetail(this.caricatureId).then(response => {
      if(response.data.code == 0){
        this.chapterList = response.data.data.chapterItemVoList;
      }
      })
    },
    selectChapterFn(id){
      let selectChapter = this.chapterList[id];
      this.selectChapter = selectChapter;
      
      this.needCoinBuy(selectChapter);
      //免费章节，直接阅读
      if(selectChapter.icon == null || selectChapter.icon == 0){
        this.fetchChapter(this.caricatureId,selectChapter.id);
        return;
      }

      //检测是否需要使用金币购买
      if(selectChapter.icon && selectChapter.icon > 0){
        this.needCoinBuy(selectChapter);
        return;
      }else{
        //检测是否是vip专属
        if(selectChapter.isVip){
        //检测用户是否已登录
        if (!this.$store.state.isLoggedIn) {
          this.showLoginDialog = true;
          return;
        }
        //获取用户信息
        this.getUserInfo();
        //检测用户的vip是否到期
        if(this.checkVipIsExpired(this.userInfo.vip)){
          alert('您的vip已到期，请先充值');
          return;
        }else{
          //直接进行查看
          this.fetchChapter(this.caricatureId,selectChapter.id);
        }

        }else{
          //直接进行查看
          this.fetchChapter(this.caricatureId,selectChapter.id);
        }
      }
    },
    checkCaricatureIsBuy(id){
      let data = {
        caricatureId:this.caricatureId,
        chapterId:id
      }
      return new Promise((resolve, reject) => {
        apiService.checkCaricatureIsBuy(data).then(response => {
          if(response.data.code == 0){
            resolve(response.data.data);
          }else{
            resolve(false);
          }
        }).catch(error => {
          console.error('获取章节失败:', error);
          reject(error);
        });
      });
    },
    getUserInfo(){
      apiService.getUserInfo().then(response => {
        if(response.data.code == 0){
          this.userInfo = response.data.data;
        }
      }).catch(error => {
        console.error('获取用户信息失败:', error);
      });
    },
    checkVipIsExpired(vip){
      let now = new Date();
      let expired = new Date(vip.expiredTime);
      return now > expired;
    },
    //需要使用金币购买
    needCoinBuy(chapter){
      //检测用户是否已登录
      if (!this.$store.state.isLoggedIn) {
        this.showLoginDialog = true;
        return;
      }

      //检测用户信息是否获取
      if(!this.userInfo){
        this.getUserInfo();
      }
       //检测用户是否已购买
       this.checkCaricatureIsBuy(chapter.id).then(isBought => {
        if(isBought){
          this.fetchChapter(this.caricatureId, chapter.id);
        }else{
          if(this.autoNextChapter){
            //直接购买下一章节
            this.handlePurchase();
          }else{
          //显示购买弹窗
          this.dialogVisible = true;
          }
        }
       });
    },
    
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleCloseBuyService(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          //重新获取用户信息
          this.getUserInfo();
          done();
        })
        .catch(() => {});
    },
    handlePurchase(){
      //处理购买逻辑
      let data = {
        caricatureId:this.caricatureId,
        chapterId:this.selectChapter.id,
        coin:this.selectChapter.icon
      }
      apiService.buyCaricatureChapter(data).then(response => {
        if(response.data.code == 0){
          this.$notify({
            title: '成功',
            message: '购买章节成功',
            type: 'success'
          });
          this.fetchChapter(this.caricatureId, this.selectChapter.id);
          this.dialogVisible = false;
        }
      }).catch(error => {
        this.$notify({
          title: '失败',
          message: '购买章节失败',
          type: 'error'
        });
        console.error('购买章节失败:', error);
      });
    },
    onLoginSuccess() {
      this.getUserInfo();
      // 重新尝试购买章节
      this.needCoinBuy(this.selectChapter);
    }
  },
}
</script>

<style scoped>
.read-chapter {
  position: relative;
}

.chapter-list {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.chapter-list ul {
  text-align: center;
  padding:0 10px
}

.chapter-item {
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 3px;
  transition: background-color 0.3s;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
}

.chapter-item:last-child {
  border-bottom: none;
}


.dialog-content {
  text-align: start;
}

.coin-info {
  margin: 20px 0;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 10px;
  padding-top: 20px;
}

.purchase-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.purchase-btn{
  background-color: #7fc63a;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}
.purchase-info span {
  font-size: 20px;
  font-weight: bold;
  color: #f0ad4e;
}

.coin-icon {
  font-size: 20px;
  margin-right: 5px;
  color: #f0ad4e;
}

.el-checkbox {
  margin-top: 10px;
}
</style>