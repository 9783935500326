<template>
  <div class="author-sidebar">
    <div class="sidebar-group">
      <h3>
        <i class="icon-author-center"></i>
        作者中心
      </h3>
      <ul>
        <li><router-link to="/author-dashboard">作者中心</router-link></li>
      </ul>
    </div>
    <div class="sidebar-group">
      <h3>
        <i class="icon-comic-management"></i>
        漫画管理
      </h3>
      <ul>
        <li><router-link :to="authorLink('/my-comics')">我的漫画</router-link></li>
        <li><router-link :to="authorLink('/author/add-caricature')">新增漫画</router-link></li>
        <li><router-link :to="authorLink('/reader-comments')">读者评论</router-link></li>
      </ul>
    </div>
    <div class="sidebar-group">
      <h3>
        <i class="icon-income-management"></i>
        收入管理
      </h3>
      <ul>
        <li><router-link :to="authorLink('/income-statement')">收入明细</router-link></li>
        <li><router-link :to="authorLink('/settlement-records')">结算记录</router-link></li>
        <li><router-link to="/author-certification">作者认证</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthorSidebar',
  computed: {
    isAuthorCertified() {
      // 这里需要从 Vuex store 或其他状态管理中获取作者认证状态
      return this.$store.state.author.isCertified;
    }
  },
  methods: {
    authorLink(path) {
      // return this.isAuthorCertified ? path : '/author-certification';
      return path;
    }
  }
}
</script>

<style scoped>
.author-sidebar {
  width: 200px;
  background-color: #fff;
  text-align: center;
  margin: 20px;
}

.sidebar-group {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar-group h3 {
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

.sidebar-group h3 i {
  margin-right: 10px;
  font-size: 20px;
}

.icon-author-center::before {
  content: '\1F4DD'; /* 使用 Unicode 字符作为临时图标 */
}

.icon-comic-management::before {
  content: '\1F4D6'; /* 使用 Unicode 字符作为临时图标 */
}

.icon-income-management::before {
  content: '\1F4B0'; /* 使用 Unicode 字符作为临时图标 */
}

.sidebar-group ul {
  list-style-type: none;
  padding: 0;
  font-size: 12px;
}

.sidebar-group li {
  font-size: 14px;
  color: #666;
  padding: 5px 0;
}

.sidebar-group li a {
  text-decoration: none;
  color: inherit;
}

.sidebar-group li:hover,
.sidebar-group li a:hover {
  color: #ff6600;
}
</style>