<template>
  <div class="user-center-layout">
    <UserSidebar />
    <div class="main-content">
      <div class="header">
        <div class="header-title">
          <h4>我的评论</h4>
          <p>评论数: {{ commentCount }} 条</p>
        </div>
        <div class="actions">
          <label for="time-filter">选择时间:</label>
          <select id="time-filter" v-model="selectedTime" @change="filterComments">
            <option value="all">全部</option>
            <option value="lastWeek">最近一周</option>
            <option value="lastMonth">最近一个月</option>
            <option value="lastYear">最近一年</option>
          </select>
        </div>
      </div>
      <div class="comment-list">
        <div 
          v-for="comment in filteredComments" 
          :key="comment.id" 
          class="comment-item"
        >
          <div class="comment-content">
            <p>你在 <router-link :to="`/detail/${comment.caricatureId}`">{{ comment.title }}</router-link> 发表了: {{ comment.text }}</p>
            <p>{{ comment.createTime }}</p>
          </div>
          <div class="comment-actions">
            <button @click="viewComment(comment.id)">查看</button>
            <button @click="deleteComment(comment.id)">删除</button>
          </div>
        </div>
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">上一页</button>
        <span>第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
      </div>
    </div>
  </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import apiService from '@/api/api';

export default {
  name: 'CommentManager',
  components: {
    UserSidebar
  },
  data() {
    return {
      selectedTime: 'all',
      comments: [],
      filteredComments: [],
      currentPage: 1,
      totalPages: 1,
      pageSize: 4
    };
  },
  computed: {
    commentCount() {
      return this.filteredComments.length;
    },
  },
  mounted() {
    this.getComments();
  },
  methods: {
    getComments() {
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }
      apiService.getUserComment(params).then(res => {
        this.comments = res.data.data.list;
        console.log(this.comments);
        this.filteredComments = this.comments;
        this.totalPages = Math.ceil(res.data.data.total / this.pageSize);
      });
    },
    prevPage() {
      this.currentPage = Math.max(this.currentPage - 1, 1);
    },
    nextPage() {
      this.currentPage = Math.min(this.currentPage + 1, this.totalPages);
      this.getComments();
    },
    filterComments() {
      const now = new Date();
      this.filteredComments = this.comments.filter(comment => {
        const commentDate = new Date(comment.createTime);
        if (this.selectedTime === 'lastWeek') {
          return now - commentDate <= 7 * 24 * 60 * 60 * 1000;
        } else if (this.selectedTime === 'lastMonth') {
          return now - commentDate <= 30 * 24 * 60 * 60 * 1000;
        } else if (this.selectedTime === 'lastYear') {
          return now - commentDate <= 365 * 24 * 60 * 60 * 1000;
        } else {
          return true;
        }
      });
    },
    viewComment(id) {
      // 跳转到评论详情页
      this.$router.push(`/detail/${id}`);
    },
    deleteComment(id) {
      apiService.deleteUserComment(id).then(res => {
        if(res.data.code == 0){
          alert('删除成功');
          this.getComments();
        }else{
          alert('删除失败');
        }
      });
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';


.comment-list {
  margin-top: 20px;
  background-color: #fff;
  font-size: 14px;
  color: #666;
}

.comment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.comment-content {
  flex: 1;
  font-size: 14px;
}

.comment-content p {
  margin: 5px 0;
}

.comment-item button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
}

.pagination button {
  margin: 0 5px;
}

.comment-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment-actions button {
  margin: 0 5px;
}

.comment-item button:hover {
  background-color: #e65c00;
}
</style>