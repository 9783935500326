<template>
  <div class="user-center-layout">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>编辑章节</h4>
        <p>请修改以下信息来更新章节。</p>
      </div>
      <div class="actions">
        <ChapterForm 
          :isEdit="true"
          :initialData="chapterData"
          :caricatureId="caricatureId"
          @submit-success="handleSubmitSuccess" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue';
import ChapterForm from '@/components/ChapterForm.vue';
import apiService from '@/api/api';

export default {
  name: 'ChapterEdit',
  components: {
    AuthorSidebar,
    ChapterForm
  },
  data() {
    return {
      caricatureId: this.$route.params.caricatureId,
      chapterId: this.$route.params.chapterId,
      chapterData: {}
    };
  },
  created() {
    this.fetchChapterData();
  },
  methods: {
    fetchChapterData() {
      apiService.getChapterById(this.chapterId).then(res => {
        if (res.data.code === 0) {
          this.chapterData = res.data.data;
        } else {
          this.$notify.error('获取章节数据失败');
        }
      }).catch(error => {
        console.error('获取章节数据时出错:', error);
        this.$notify.error('获取章节数据时出错');
      });
    },
    handleSubmitSuccess() {
      this.$notify.success('章节更新成功');
      this.$router.push(`/author/caricature/${this.caricatureId}/chapters`);
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

h4 {
  margin-bottom: 20px;
  color: #333;
}

.header-info {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px;
}

.actions {
  background-color: #fff;
  padding: 20px;
}
</style>