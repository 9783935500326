import api from './index';

export const apiService = {
  // 注册登录相关接口
  getVerificationCode: () => api.get('app-api/api/auth/verification'), //获取验证码
  register: (phone, code, password) => api.post('app-api/api/auth/register', { phone, code, password }), //注册
  login: (phone, password) => api.post('app-api/api/auth/login', { phone, password }), //登录
  resetPassword: (phone, code, password) => api.post('app-api/api/auth/forget', { phone, code, password }), //重置密码

  getCategory: () => api.get('app-api/api/open/category/list'), //获取分类
  getHomeData: () => api.get('app-api/api/open/init'), //获取首页数据
  getRankList:(params) => api.get('app-api/api/open/ranking',{params}), //获取排行榜
  getAbout:(params) => api.get('app-api/api/content/get',{params} ), //关于我们
  

  //打赏相关
  getChargeProductList: () => api.get('app-api/api/open/charge/list'), //获取充值商品列表
  getGiftList: () => api.get('app-api/api/open/gift/list'), //获取打赏商品列表
  getDonateList: (params) => api.get('app-api/api/personal/me/nice/page', { params }), //获取打赏列表
  postDonate: (data) => api.post('app-api/api/personal/me/nice', data), //提交打赏
  postDonateTicket: (data) => api.post('app-api/api/personal/me/ticket', data), //投月票

  //订单相关
  createOrder: (data) => api.post('app-api/api/order/save', data), //创建订单
  payOrder: (data) => api.post(`app-api/api/order/pay`,data), //支付订单
  checkPayStatus: (id) => api.get(`app-api/api/order/status/${id}`), //查询支付状态
  cancelOrder: (id) => api.post(`app-api/api/order/cancel/${id}`), //取消订单
  deleteOrder: (id) => api.post(`app-api/api/order/delete/${id}`), //删除订单
  getOrderList: (params) => api.get('app-api/api/order/page', { params }), //获取订单列表
  refundOrder: (id) => api.post(`app-api/api/order/refund/${id}`), //退款订单

  //漫画列表筛选,详情,章节
  getCaricatureList: (params) => api.get('app-api/api/open/page', { params }), //获取漫画列表
  getCaricatureDetail: (id) => api.get(`app-api/api/open/detail/${id}`), //获取漫画详情
  getCaricatureChapter: (id,chapterId) => api.get(`app-api/api/open/query/${id}/${chapterId}`), //获取漫画章节详情
  checkCaricatureIsBuy: (data) => api.post(`app-api/api/personal/me/buy/check`,data), //获取漫画章节是否购买
  checkCaricatureIsFavorite: (id) => api.get(`app-api/api/personal/me/collect/status/${id}`), //获取漫画是否收藏
  buyCaricatureChapter: (data) => api.post(`app-api/api/personal/me/buy`,data), //购买章节

  //漫画管理
  addCaricature: (data) => api.post('app-api/api/author/caricature/create', data), //新增漫画
  updateCaricature: (data) => api.post('app-api/api/author/caricature/create', data), //修改漫画
  deleteCaricature: (id) => api.post(`app-api/api/author/comic/delete/${id}`), //删除漫画

  //作者中心
  getSettlement: (params) => api.get('app-api/api/author/done', { params }), //结算
  getBill: (params) => api.get('app-api/api/author/bill', { params }),   //收入
  getComment: (params) => api.get('app-api/api/author/comment', { params }),  //评论
  getAuthorReview: () => api.get('app-api/api/author/author_check_status'),  //作者审核
  authorAuth: (data) => api.post('app-api/api/author/auth', data),  //作者认证
  getStatement: (params) => api.get('app-api/api/author/bill', { params }),  //收入明细
  getReaderComment: (params) => api.get('/app-api/api/author/comment', { params }),  //读者评论
  getAuthorCaricature: (params) => api.get('/app-api/api/author/caricature/list', { params }),  //作者漫画

  //用户中心
  getUserInfo: () => api.post('app-api/api/personal/me/detail'), //用户信息
  updateUserInfo: (data) => api.post('app-api/api/personal/me/change', data), //修改用户信息
  changePassword: (data) => api.post('app-api/api/personal/me/reset', data), //修改密码
  getFlow: (params) => api.get('app-api/api/personal/me/follow', { params }), //收藏历史
  postFlow: (data) => api.post('app-api/api/personal/me/follow/save', data), //新增收藏
  getUserComment: (params) => api.get('app-api/api/personal/comment/page', { params }), //用户评论
  postUserComment: (data) => api.post('app-api/api/personal/comment/save', data), //发布评论
  deleteUserComment: (id) => api.get(`app-api/api/personal/comment/remove/${id}`), //删除评论
  getConsumeRecord: (params) => api.get('app-api/api/personal/me/log/list', { params }), //获取消费记录
  getBuyRecord: (params) => api.get('app-api/api/personal/me/buy/list', { params }), //获取购买记录

  //文件上传
  uploadFile: (data) => api.post('infra/file/upload', data), //上传文件
};

export default apiService;