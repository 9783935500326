<template>
  <div class="rank-page">
    <div class="rank-tabs">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="全部榜单" name="9"></el-tab-pane>
        <el-tab-pane label="年度榜" name="0"></el-tab-pane>
        <el-tab-pane label="本月榜" name="1"></el-tab-pane>
        <el-tab-pane label="本周榜" name="2"></el-tab-pane>
        <el-tab-pane label="今日榜" name="3"></el-tab-pane>
        <el-tab-pane label="上升榜" name="4"></el-tab-pane>
        <el-tab-pane label="评分榜" name="5"></el-tab-pane>
        <el-tab-pane label="收藏榜" name="6"></el-tab-pane>
        <el-tab-pane label="打赏榜" name="7"></el-tab-pane>
        <el-tab-pane label="月票榜" name="8"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="rank-content">
      <div v-if="activeTab === '9'">
        <AllRankList :rankTypeList="rankTypeList" @update-active-tab="updateActiveTab"/>
      </div>
      <div v-else>
        <RankList :qtype="Number(activeTab)" :rankTypeList="rankTypeList" />
      </div>
    </div>
  </div>
</template>

<script>
import AllRankList from '@/components/AllRankList.vue';
import RankList from '@/components/RankList.vue';

export default {
  name: 'Rank',
  props: {
    type: {
      type: String,
      default: '9'
    }
  },
  components: {
    AllRankList,
    RankList
  },
  mounted() {
    this.activeTab = this.type;
  },
  data() {
    return {
      activeTab: this.type,
      rankTypeList: [{
        name: '全部榜单',
        type: 9,
        title: '全部榜单'
      },{
        name: '年度榜',
        type: 0,
        title:'总点击'
      },{
        name: '本月榜',
        type: 1,
        title:'月点击'
      },{
        name: '本周榜',
        type: 2,
        title:'周点击'
      },{
        name: '今日榜',
        type: 3,
        title:'日点击'
      },{
        name: '上升榜',
        type: 4,
        title:'上升榜'
      },{
        name: '评分榜',
        type: 5,
        title:'总得分'
      },{
        name: '收藏榜',
        type: 6,
        title:'周收藏'
      },{
        name: '打赏榜',
        type: 7,
        title:'总礼物'
      },{   
        name: '月票榜',
        type: 8,
        title:'总月票'
      }]
    };
  },
  methods: {
    handleTabClick(tab) {
      this.activeTab = tab.name;
    },
    updateActiveTab(newTab) {
      this.activeTab = newTab;
    }
  }
};
</script>

<style scoped>
.rank-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.rank-tabs {
  margin-bottom: 20px;
}

.el-tabs__item {
  font-size: 16px;
}

.el-tabs__item.is-active {
  color: #7fc63a !important;
}
::v-deep .el-tabs__item:hover {
  color: #7fc63a !important;
}

.el-tabs__active-bar {
  background-color: #7fc63a !important;
}
</style>